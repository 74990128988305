
<template>
<div class="row justify-content-center" style="height: 100vh;">
    <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center flex-column mx-auto">
        <div class="card">
            <div class="card-body p-4">
                <div class="text-center mt-4">
                    <img width="40" :src="`${$store.state.domain}/images/sand-timer.gif`" />
                    <h4 class="mt-3 mb-1">Please wait!</h4>
                    <p class="text-muted mb-3">Authentication in progress ...</p>
                    <b-button @click="redirect" class="btn btn-primary mt-3"><i class="mdi mdi-reply mr-1"></i> Return Home</b-button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    methods: {
        redirect(){
            location.replace(this.$store.state.domain)
        },
    },
    async created(){
        if(!this.$route.query.token){
            location.replace(this.$store.state.domain+'/logout');return
        }
        let token = JSON.parse(atob(this.$route.query.token));
        if(typeof token !== 'object' && token === null){
            location.replace(this.$store.state.domain+'/logout');return
        }
        this.$store.dispatch("changeLoaderValue", true)
        let formData = new FormData();
        formData.append('data', JSON.stringify(token));
        this.$http.post(`${this.$store.state.domain}/api/auto-login`, formData)
        .then((response)=> {
            this.$store.dispatch("changeLoaderValue", true)
            if(response.data.success){
                const expirationTime = new Date( new Date().getTime() + +response.data.expiresIn * 1000);
                const config = {
                    role: response.data.role,
                    token: response.data.accessToken,
                    tokenExpirationDate: expirationTime.toISOString(),
                    user: response.data.user,
                }
                const encryptedValue = btoa(escape(JSON.stringify(config)));
                localStorage.setItem(this.$store.state.localUserKey, encryptedValue);
                window.location = `${location.origin}${this.$route.query.intended}`;
            }else{
                location.replace(this.$store.state.domain+'/logout')
            }
        }).catch(err => { location.replace(this.$store.state.domain+'/logout') })
    }
}

</script>
